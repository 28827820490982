@import 'comm.scss';
.page-404{
    padding: 170px 0;
    text-align: center;
    background: linear-gradient(-30deg, #D6E1F0 0%, #EEF3F8 100%);
    .img{
        display: block;
        height: 183px;
        margin: 0 auto;
    }
    .name{
        margin: 20px 0 15px;
        font-size: 30px;
        line-height: 40px;
        color: #333;
    }
    .text{
        font-size: 16px;
        line-height: 20px;
        color: #999;
    }
    .home-btn{
        margin-top: 55px;
        display: inline-block;
        line-height: 48px;
        border: 1px solid $color;
        font-size: 16px;
        padding: 0 40px;
    }
}

@media (max-width: 640px) {
    .page-404 {
        padding: 50px 0;
        .img{
            height: 80px;
        }
        .name{
            font-size: 14px;
            line-height: 16px;
            margin: 15px 0 20px;
        }
        .text{
            font-size: 12px;
            line-height: 14px;
        }
        .home-btn{
            margin-top: 60px;
            font-size: 14px;
            line-height: 30px;
            padding: 0 20px;
        }
    }
}