@charset "UTF-8";
$color: rgba(0, 84, 166, 1);
$color1: #05458C;
@font-face {
    font-family: SourceHanSansSC-Medium;
    src: url(../font/SourceHanSansCN-Regular.woff);
}
*{
    font-family: 'SourceHanSansSC-Medium';
}
.en *{
    font-family: 'Arial';
}
a{
    text-decoration: none !important;
}
.container{
    max-width:1400px;
}

@mixin scroll($color){
    &::-webkit-scrollbar{
      width: 4px;
      border-raidus:4px;
      background-color: rgba(255, 255, 255, 0.34);
    }
    &::-webkit-scrollbar-track{
      background:rgba(255,255,255,0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.22);
    }
    &::-webkit-scrollbar-thumb{
      background:$color;
      border-radius:4px;
    }
  }
#loading{
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    img{
        position: absolute;
        left:50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 200px;
        height: auto;
        display: block;
    }
}
header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 30px 0px;
    &.active{
        transition: all 0.4s;
    }
    &.active, &.page-detail{
        padding: 20px 0px;
        background: #fff;
        .navbar-nav .dropdown-menu:after{
            height: 60px;
            top: 57px;
        }
        .navbar-toggler{
            .icon1{
                display: none;
            }
            .icon2{
                display: block;
            }
        }
        .logo1{
            display: none;
        }
        .logo2{
            display: block;
        }
        .nav-link{
            color: #333 !important;
        }
        .language svg, .search-btn{
            color: #333;
        }
    }
    &.page-detail{
      .navbar-nav .active .pc-nav-link{
        position: relative;
        color: $color;
        &::after{
          display: block;
          content: '';
          position: absolute;
          top: calc(100% + 27px);
          left: 0;
          width: 100%;
          height: 2px;
          background: $color;
          z-index: 2;
        }
      }
    }
    .navbar{
        position: static;
        padding: 0;
    }
    .navbar-brand{
        padding: 0;
        margin: 0;
    }
    .navbar-toggler {
        display: block !important;
        margin-left: 50px;
        img{
            display: block;
            width: 25px;
            height: auto;
            color: #fff;
        }
        .icon2{
            display: none;
        }
    }
    .logo{
        display: block;
        width: auto;
        height: 48px;
    }
    .logo2{
        display: none;
    }
    .navbar-nav .nav-link{
        font-size: 18px;
        padding: 5px 10px;
        line-height: 1.3;
        font-weight: 500;
        color:#fff;
        &:hover{
            color: $color;
        }
    }
    .navbar-nav{
        flex: 1;
        justify-content: center;
        .nav-item{
            padding: 6px 25px;
            position: static;
        }
        .dropdown-toggle{
            display: none;
        }
        .pc-nav-link{
            display: block;
        }
        .dropdown-menu{
            padding: 0 10px;
            margin-top: 0;
            border: none;
            border-radius: 0;
            left: 0;
            width: 100% !important;
            background: #FFFFFF;
            font-size: 0;
            text-align: center;
            transition: all .7s;
            overflow: hidden;
            border-top: 1px solid #e5e5e5;
            &::after{
                position: fixed;
                content: '';
                left: 0;
                top: 70px;
                width: 100%;
                background: transparent;
                height: 60px;
                z-index: 22;
            }
            .dropdown-item{
                display: inline-block;
                vertical-align: top;
                line-height: 80px;
                width: auto;
                white-space: normal;
                color: #666;
                text-align: center;
                font-size: 18px;
                padding: 0;
                margin: 0 60px;
                position: relative;
                z-index: 23;
                .img{
                    display: block;
                    width: 43px;
                    height: 37px;
                    margin: 0 auto;
                    object-fit: contain;
                }
                .img1{
                    display: none;
                }
                &::after{
                    display: block;
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 0;
                    height: 3px;
                    transform: translateX(-50%);
                    background: $color;
                    transition: all 0.4s;
                }
                &:hover{
                    color: $color;
                    background: transparent;
                    &::after{
                        width: 50%;
                    }
                    .img1{
                        display: none;
                    }
                    .img2{
                        display: block;
                    }
                }
            }
        }
    }
    .language{
        display: inline-block;
        vertical-align: middle;
        svg{
            width: 19px;
            height: 19px;
            color: #fff;
        }
        .dropdown-toggle::after{
            margin-left: 0.4em;
        }
        .nav-link{
            display: inline-block;
            padding: .5rem .3rem;
            color: #fff;
            vertical-align: middle;
        }
        .dropdown-menu{
            left: 50%;
            border-radius: 5px;
            transform: translateX(-50%);
            background: #FFFFFF;
            min-width: 140px;
            padding: 15px 0;
            text-align: center;
            border: none;
            margin-top: 15px;
            box-shadow: 0 0 16px -1px rgba(0,0,0,0.1) !important;
            &::after{
              display: block;
              content: '';
              border: 8px solid transparent;
              border-bottom-color: #fff;
              position: absolute;
              left: 50%;
              top: -16px;
              margin-left: -8px;
            }
            .dropdown-item{
                font-size: 18px;
                color: rgba(153, 153, 153, 1);
                &:hover{
                    background: transparent;
                    color: $color;
                    text-decoration: underline;
                }
            }
        }
    }
    .form-search{
        display: inline-block;
        vertical-align: middle;
        position: static;
        margin-left: 40px;
        .flex{
            width: 600px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 100px;
            margin-left: auto;
            margin-right: 370px;
            background: rgba(0,0,0,0.2);
            input{
                padding-left: 73px;
                flex: 1;
                background: transparent;
                border: none;
                line-height: 70px;
                height: 70px;
                border-radius: 0;
                font-size: 18px;
                color: #fff;
                &::placeholder{
                    color: #fff;
                }
            }
            svg{
                position: absolute;
                left: 38px;
                top: 50%;
                width: 26px;
                height: 22px;
                transform: translateY(-50%);
                z-index: 2;
                color: #fff;
                opacity: .6;
            }
            .btn{
              padding: 0 40px;
              background-color: $color;
              border-radius: 50px;
              line-height: 58px;
              font-size: 16px;
              margin-right: 6px;
              color: #fff;
            }
        }
        .dropdown-menu{
            right: 0;
            left: auto;
        }
        input,button{
            display: inline-block;
        }
        .dropdown-menu{
            position: absolute;
            top: 100%;
            width: 100%;
            margin-top: 0;
            padding:25px 0;
            background: rgba(255,255,255,0.9);
            white-space: nowrap;
            input{
                outline: none !important;
                margin-right: 8px !important;
                color: #fff;
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }
    .search-btn{
        width: 18px;
        height: 17px;
        color: #fff;
    }
}
.more-comm{
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
    &::after{
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        width: 15%;
        height: 3px;
        background: $color;
        transition: all 0.4s;
    }
    &:hover{
        text-decoration: none;
        &::after{
            width: 100%;
            background: $color !important;
        }
        .txt, svg{
            color: $color !important;
        }
    }
    .txt{
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }
    svg{
        display: inline-block;
        color: $color;
    }
}
.banner-comm2{
    position: relative;
    overflow: hidden;
    img{
        display: block;
        width: 100%;
        animation: img 10s linear infinite alternate;
    }
    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        line-height: 1.2;
        color: #fff;
    }
}
@keyframes img {
    0%{
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}
.product-menu{
    padding: 0 !important;
    .container{
        align-items: inherit;
        justify-content:flex-start;
    }
    .list{
        display: flex;
        width: 33.33%;
        padding: 60px 0;
        &:nth-child(3), &:last-child{
            .list-box{
                border-right: none;
            }
        }
        .img{
            margin-left: 30px;
            display: block;
            width: 100px;
            height: 70px;
            object-fit: contain;
        }
        .list-box{
            flex: 1;
            margin-left: 80px;
            text-align: left;
            border-right: 1px solid rgba(0,0,0,0.1);
            .sub-title{
                font-size: 20px;
                font-weight: 500;
                color: #333;
            }
            .a-list{
                margin-top: 10px;
                a{
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1.9;
                    color: #666;
                    &:hover{
                        color: $color;
                    }
                }
            }
        }
    }
    .line{
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
}
footer{
    padding: 60px 0 35px;
    background: #3A3C41;
    &.en-footer {
      .follow-list{
        margin-bottom: 0;
      }
      .foot-info{
        flex-wrap: wrap;
      }
      .left{
          flex: 1;
      }
      .right{
          padding-left: 3%;
      }
      .foot{
        margin-top: 60px;
      }
      .foot-nav{
        flex: none;
        width: 100%;
        padding: 0;
        margin: 50px -10px 0;
      }
      .follow-list-box{
          margin-top: 15px;
      }
      .email-box{
        display: flex;
        align-items: center;
        .btn{
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }
    .foot, .foot-info{
        display: flex;
        justify-content: space-between;
    }
    .foot{
        margin-top: 95px;
    }
    .logo{
        img{
            display: block;
            height: 54px;
            width: auto;
        }
    }
    .follow{
      display: inline-block;
        img{
            display: block;
            width: 110px;
            height: auto;
            margin-top: 40px;
        }
        .name{
            margin-top: 5px;
            font-size: 14px;
            text-align: center;
            color: rgba(255,255,255,0.6);
        }
    }
    .title{
        font-size: 16px;
        line-height: 1.1;
        height: 16px;
        color: #fff;
        font-weight: 500;
        margin-bottom: 20px;
        a{
            font-size: 16px !important;
            color: #fff !important;
            line-height: 1.1 !important;
        }
    }
    .foot-nav{
        font-size: 0;
        padding: 0 120px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .list{
            display: inline-block;
            vertical-align: top;
            padding: 0 10px;
        }
        a{
            line-height: 1.9;
            font-size: 14px;
            color: rgba(255,255,255,0.6);
            &:hover{
                color: #fff;
                text-decoration: underline !important;
            }
        }
    }
    .follow-box{
        .emial{
            display: block;
            width: 320px;
            height: 40px;
            line-height: 40px;
            border: none;
            outline: none;
            border-bottom: 1px solid rgba(255,255,255,0.6);
            border-radius: 0;
            font-size: 14px;
            color: #fff;
            background: transparent;
        }
        .btn{
            margin-top: 20px;
            padding: 0;
            border-radius: 0;
            cursor: pointer;
            width: 120px;
            line-height: 50px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background: #f4a000;
            border-radius: 5px;
            transition: all 0.4s;
            &:hover{
              transform: scale(.9);
            }
        }
    }
    .follow-list{
        padding-top: 10px;
        margin-bottom: 45px;
        a{
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
        }
        img{
            display: block;
            width: auto;
            height: 21px;
            object-fit: contain;
            transition: all 0.4s;
            &:hover{
                transform: translateY(-5px);
            }
        }
    }
    .foot{
        .copyright, .name, a{
            font-size: 14px;
            color: rgba(255,255,255,0.3);
        }
    }
}
.footer-message{
    padding: 20px 0;
    background: #F2F2F2;
    .container{
        display: flex;
        height: 80px;
        .list{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            border-right: 1px solid rgba(0,0,0,0.15);
            &:last-child{
                border-right: none;
            }
            &:hover{
                .name{
                    color: #0057a2;
                }
                .icon{
                    display: none;
                }
                .icon1{
                    display: block;
                }
            }
        }
        img{
            display: block;
            width: auto;
            height: 34px;
        }
        .icon1{
            display: none;
        }
        .name{
            margin-left: 60px;
            display: block;
            font-size: 24px;
            color: #333;
        }
        .sub-name{
            font-size: 14px;
            color: #666;
            text-align: center;
            display: block;
            width: 100%;
            padding-left: 15%;
        }
    }
}

.server{
    padding: 100px 0 120px;
    .tip{
        font-size: 20px;
        line-height: 1.1;
        color: #333;
        font-weight: 500;
        padding: 0 0 50px;
    }
    .server-box{
        margin: 0 -20px;
        font-size: 0;
    }
    .list{
        display: inline-block;
        width: 50%;
        padding: 0 20px 30px;
        .name{
            display: block;
            font-size: 16px;
            line-height: 1.1;
            color: #000;
            span{
                color: $color;
            }
        }
        input, textarea{
            margin-top: 8px;
            display: block;
            width: 100%;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 16px;
            color: #333;
            background: rgba(190, 204, 219, .2);
            &:focus{
                background: rgba(192,204,219, .5);
            }
        }
        textarea{
            height: 130px;
            line-height: 40px;
        }
    }
    .w100{
        width: 100%;
    }
    .s-btn{
        width: 120px;
        line-height: 60px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        background: #f4a000;
        border-radius: 5px;
        transition: all 0.4s;
        &:hover{
          transform: scale(0.9);
        }
    }
}
.modal-dialog{
    width: 70%;
    max-width: 100%;
    .modal-header{
        border: none;
    }
    .modal-body{
        padding: 0 65px 80px;
    }
    .server{
        padding: 0;
    }
}
.success-dialog{
    width: 40%;
    .success-icon{
        display: block;
        width: 120px;
        height: auto;
        margin: 0 auto;
    }
    video{
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .success-text{
        margin-top: 28px;
        text-align: center;
        font-size: 20px;
        line-height: 1.5;
        font-weight: bold;
        color: #333;
    }
}
.pagination-comm{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0;
  input{
    display: inline-block;
    width: 80px;
    margin-left: 10px;
    border: 1px solid #dee2e6;
    box-shadow: none;
    text-align: center;
    padding: 0.23rem;
    color: #333;
    outline: none;
  }
}
.pagination{
    margin: 0 25px 0 0;
    justify-content: center;
    .page-link{
        text-align: center;
    }
}
.page-item.active .page-link, .page-item:hover .page-link{
    background: $color;
    border-color: $color;
    color: #fff;
}
.page-item.disabled .page-link{
    background: $color;
    border-color: $color;
    color: #fff;
}

.mobile-nav{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0,0,0,0.7);
    height: 100%;
    z-index: 999;
    .menu-close{
        width: 20px;
        line-height: 20px;
        text-align: center;
        margin: 44px 35% 44px auto;
        font-size: 30px;
        display: block;
        color: #666;
        cursor: pointer;
    }
    .nav-box{
        width: 33.33%;
        margin-left: auto;
        margin-right: 0;
        height: 100vh;
        background: #fff;
        overflow: auto;
    }
    .nav-list{
        .list{
            cursor: pointer;
            .list-head{
                padding: 0 35% 0 12%;
                height: 60px;
                display: flex;
                align-items: center;
                .name{
                    flex: 1;
                    font-size: 20px;
                    color: #333;
                    font-weight: 500;
                }
                .icon{
                    width: 20px;
                    height: 20px;
                    background: url(../image/comm/+.png) no-repeat 50% 50%;
                    background-size: contain;
                }
            }
            .list-box{
                display: none;
                padding: 20px 35% 20px 12%;
                .s-list{
                    a{
                        display: inline-block;
                        font-size: 16px;
                        line-height: 1.1;
                        padding: 8px 0;
                        margin: 4px 0;
                        color: rgba(51,51,51,1);
                        font-weight: 500;
                        border-bottom: 2px solid transparent;
                        transition: all 0.4s;
                        &:hover{
                            color: $color;
                            border-color: $color;
                        }
                    }
                }
            }
            &.active{
                .list-head{
                    background: $color;
                    .name{
                        color: #fff;
                    }
                    .icon{
                        background: url(../image/comm/-.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
.en header .navbar-nav .nav-item:nth-child(3) .dropdown-menu{
  padding: 0 110px;
}

@keyframes banner {
    0%{
        background-position: 50% 55%;
    }
    25% {
        background-position: 50% 50%;
    }
    50% {
        background-position: 50% 45%;
    }
    75% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 50% 55%;
    }
}
@media (min-width: 2000px){

}
@media (min-width: 1700px){
    
}
@media (max-width: 1700px) {
   
}
@media (max-width: 1200px){
    
}
@media (max-width: 991px){
    header .navbar-nav{
        display: none;
    }
    header .language svg{
        width: 16px;
        height: 16px;
        display: none;
    }
    header .language .nav-link{
        display: block;
        font-size: 14px;
        padding: 5px 8px;
    }
    header .form-search{
        display: flex;
        align-items: center;
        margin-left: 0;
        padding: 0 5px;
    }
    header .language .dropdown-menu .dropdown-item{
        font-size: 12px;
    }
    header .search-btn{
        width: 16px;
        height: 16px;
        margin-left: 5px;
        margin-right: 5px;
    }
    header .navbar-toggler{
        margin-left: 0;
        padding: 5px 15px 5px 5px;
    }
    header{
        padding: 0 !important;
        .right{
            display: flex;
            justify-content: flex-end;
            margin: 0 !important;
        }
        .navbar-brand{
            padding: 0.75rem 1rem ;
            padding-left: 15px !important;
        }
        .navbar-collapse{
            display: inline-block !important;
            padding: 0;
            background-color: transparent;
            flex-basis: auto;
            font-size: 0;
        }
        .navbar-toggler svg{
            color: #333;
        }
        .nav-link{
        }
        .navbar-nav .nav-item{
            margin: 0 1rem;
        }
    }
    header .form-search .dropdown-menu{
        padding: 10px 0;
    }
    header .form-search .flex{
        margin: 0 auto !important;
        width: 85%;
        input{
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            padding: 0 10px 0 40px;
        }
        svg{
            width: 15px;
            height: 15px;
            left: 15px;
        }
        .btn{
          padding: 0 15px;
          border-radius: 40px;
          line-height: 24px;
          margin-right: 3px;
          font-size: 12px;
        }
    }
    header .bg{
        display: none;
    }
    header .navbar-nav .dropdown-toggle{
        display: block;
        font-size: 16px;
    }
    header .navbar-nav .nav-link{
        font-size: 16px;
    }
    header .navbar-nav .pc-nav-link{
        display: none;
    }
    header .navbar-nav .dropdown-menu{
        padding: .5rem 0;
        transform: none;
    }
    header .navbar-nav .dropdown-menu .dropdown-item{
        padding: 10px 0;
    }
    .dropdown-toggle::after{
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 17px;
    }
    .select-comm .btn{
        width: 100px;
        font-size: 14px;
    }
    .page-link{
        width: 40px;
        height: 40px;
        line-height: 38px;
        padding: 0;
    }
    .pagination-comm{
      margin: 25px 0;
      flex-wrap: wrap;
    }
    .pagination-comm input{
      line-height: 35px;
      font-size: 14px;
      width: 50px;
      padding: 0;
    }
    footer .foot-nav .title{
        font-size: 14px;
        line-height: 1.3;
    }
    footer .foot-nav a{
        font-size: 14px !important;
        line-height: 1.7;
    }
    .comm-more{
        width: 120px;
        height: 35px;
        font-size: 14px;
        .icon{
            width: 10px;
            height: 10px;
        }
    }
    .breadcrumb{
        margin-top: -30px;
        height: 30px;
        padding: 0;
        border-radius: 0;
        .container{
            height: 100%;
            display: flex;
            align-items: center;
            a{
                display: inline-block;
                font-size: 13px;
            }
        }
    }
    header .navbar-toggler img{
        width: 21px;
    }
    footer .foot-info {
        flex-wrap: wrap;
        .left{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .follow-box{
            width: 100%;
        }
    }
    footer.en-footer {
      .foot-info .left{
        width: auto;
      }
      .foot-info .follow-box{
        width: auto;
      }
    }
    footer .foot-nav{
        width: 100%;
        padding: 0;
        flex-wrap: wrap;
        font-size: 0;
    }
    footer .title{
        line-height: 1.2;
        height: 28px;
        font-size: 14px;
    }
    footer .follow-box .emial{
        width: 70%;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
    }
    footer .follow-box .btn{
        width: 80px;
        line-height: 28px;
        font-size: 14px;
        margin-top: 8px;
    }
    footer .foot-nav .list{
        width: auto;
        margin-top: 15px;
    }
    footer .foot .copyright, footer .foot .name, footer .foot a{
        font-size: 12px;
    }
    footer .follow-list img{
        height: 15px;
    }
    footer .logo img{
        height: 30px;
    }
    footer .follow img{
        width: 80px;
    }
    footer .follow .name{
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
    }
}

@media (max-width: 640px){
    footer{
      padding-top: 25px;
    }
    footer.en-footer .email-box .btn{
      margin-left: 10px;
    }
    header .language .dropdown-menu{
      margin-top: 8px;
    }
    .more-comm{
        padding-bottom: 6px;
    }
    .more-comm .txt{
        font-size: 13px;
    }
    .more-comm:after{
        width: 20%;
        height: 1px;
    }
    .container{
        width: 100%;
        max-width: 100%;
        padding: 0 15px;
    }
    footer .foot-nav .list{
        width: 50%;
    }
    header .logo{
        height: 30px;
    }
    header .navbar-brand{
        padding: 0.6rem 0.75rem;
    }
    header .navbar-toggler svg{
        width: 25px;
        height: 25px;
    }
    .banner .kv, .banner2 img{
        height: 200px;
        object-fit: cover;
    }
    .banner .title, .banner2 .info .title{
        font-size: 16px;
    }
    .banner-comm2 img{
      height: 150px;
    }
    .banner-comm2 .title{
      font-size: 24px;
    }
    .footer-message{
        padding: 20px 0;
    }
    .footer-message .container img{
        height: 20px;
    }
    .footer-message .container .name{
        font-size: 16px;
        margin-left: 10px;
    }
    .mobile-nav .nav-box{
        width: 60%;
    }
    .mobile-nav .menu-close{
        width: 18px;
        line-height: 18px;
        font-size: 18px;
        margin: 20px 25% 20px auto;
    }
    .mobile-nav .nav-list .list .list-head{
        padding: 0 25% 0 12%;
        height: 35px;
        .name{
            font-size: 16px;
        }
        .icon{
            width: 10px;
            height: 10px;
        }
    }
    .mobile-nav .nav-list .list .list-box .s-list a{
        font-size: 14px;
        margin: 5px 0;
    }
    .server .tip{
      font-size: 14px;
      line-height: 1.5;
      padding-bottom: 15px;
    }
    .server .server-box{
      margin: 0;
    }
    .server .s-btn{
      width: 80px;
      line-height: 32px;
      font-size: 14px;
    }
    .server .list{
      width: 100%;
      padding: 0 0 20px;
      .name, .name span{
        font-size: 14px;
      }
      input, textarea{
        margin-top: 10px;
        line-height: 32px;
        height: 32px;
        padding: 0 10px;
        font-size: 14px;
      }
      textarea{
        height: 90px;
      }
    }
    .success-dialog, .modal-dialog{
        width: 90%;
        margin:  10% auto 0;
    }
    .en .mobile-nav .nav-box{
        width: 70%;
    }
    .en .mobile-nav .nav-list .list .list-head{
        padding: 0 10%;
        height: 45px;
    }
    .en .mobile-nav .nav-list .list .list-box{
        padding: 10px 10%;
    }
    footer.en-footer {
      .foot-info .left{
        width: 100%;
        flex-wrap: wrap;
      }
      .foot-info .follow-box{
        width: auto;
        margin-top: 15px;
      }
      .right{
        width: 100%;
        justify-content: flex-start;
      }
    }
    footer.en-footer .follow-list-box{
      margin-top: 15px;
    }
    .footer-message .container .sub-name{
        font-size: 12px;
        padding-left: 0;
        display: none;
    }
    .footer-message .container{
        height: auto;
    }
    .pagination-comm{
      margin-bottom: 30px;
    }
}